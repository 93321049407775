const HEADER_ID = 'siteHeader';

const ELEMENTS = {
  HEADER_ID,
};

const TABLET_BREAKPOINT = 768;
const DESKTOP_BREAKPOINT = 1024;

const ANCHOR_LINKS_DESKTOP_OFFSET = -145;
const ANCHOR_LINKS_MOBILE_OFFSET = -129;
const ANCHOR_LINKS_SETTINGS = {
  spy: true,
  hashSpy: true,
  activeClass: 'active',
  smooth: true,
  duration: 300,
  offset: 0,
};

const SPACEBAR_KEY_CODE = 32;
const DISCLAIMER_HAS_SHOWN = 'DISCLAIMER_HAS_SHOWN';
const GAVI_LAYOUT_FLAGS: string[] = ['GAVIDESIGN', 'GAVIBANNER', 'GAVIHEADER', 'GAVILOGO'];
const GUARDIUM_DESIGN = 'GUARDIUM_DESIGN';
const GUARDIUM = 'guardium';

export {
  ELEMENTS as default,
  ANCHOR_LINKS_SETTINGS,
  ANCHOR_LINKS_DESKTOP_OFFSET,
  ANCHOR_LINKS_MOBILE_OFFSET,
  TABLET_BREAKPOINT,
  DESKTOP_BREAKPOINT,
  SPACEBAR_KEY_CODE,
  DISCLAIMER_HAS_SHOWN,
  GAVI_LAYOUT_FLAGS,
  GUARDIUM_DESIGN,
  GUARDIUM,
};
