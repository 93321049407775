export const autoScrollCheckAccordion = (e) => {
  const { top } = e.currentTarget.getBoundingClientRect();

  window.scrollY - top > window.innerHeight &&
    window.scrollTo({
      top: top + window.innerHeight,
    });
};

export const articleFormatDate = (date: string): string => {
  const setDaySuffix = (day: number): string => {
    const getCorrectEnd = ({ condition }) => condition === true;
    const j = day % 10;
    const k = day % 100;
    const ends = [
      { value: 'st', condition: j === 1 && k !== 11 },
      { value: 'nd', condition: j === 2 && k !== 12 },
      { value: 'rd', condition: j === 3 && k !== 13 },
      { value: 'th', condition: true },
    ];
    const end = ends.find(getCorrectEnd)?.value;

    return `${day}${end}`;
  };

  const newDate = new Date(date.replace(/-/g, '/'));
  const month = newDate.toLocaleString('en-GB', { month: 'long' });
  const day = newDate.getDate();
  const year = newDate.getFullYear();
  const dateString = `${month} ${setDaySuffix(day)}, ${year}`;

  return dateString;
};

export default autoScrollCheckAccordion;

export const validateEmail = (email: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(String(email).toLowerCase());
};

export const validateName = (name: string) => {
  const re = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;

  return re.test(String(name).toLowerCase());
};

export const sortRelatedProducts = (
  productsLinks: string[] | undefined,
  relatedProducts: ProductPageTypes.IProductCard[]
) => {
  if (!productsLinks && relatedProducts) return relatedProducts;

  const tempArr: ProductPageTypes.IProductCard[] = [];
  productsLinks?.forEach((elLink) => {
    const node = relatedProducts.find((el) => el.link === elLink);
    if (node) {
      tempArr.push(node);
    }
  });

  return tempArr;
};

export const getGaviLayoutFlags = (
  tags: UmbracoTypes.ISingleTag[] | undefined,
  selectors: string[]
): { [key: string]: boolean } => {
  if (!tags?.length) {
    return {};
  }

  return tags.reduce((acc, { name }) => {
    if (selectors.includes(name)) {
      acc[name] = selectors.includes(name);
    }

    return acc;
  }, {});
};

export const isEmpty = (obj: unknown): boolean => {
  if (obj === undefined || obj === null) return true;
  if (Array.isArray(obj)) return !obj.length;
  if (typeof obj === 'object') return !Object.keys(obj as object).length;

  return !obj;
};

export const getStringWithoutTags = (str: string): string => {
  if (typeof str !== 'string') return '';
  const htmlTagsRegex = /<[^>]+>/g;
  const containsTags = htmlTagsRegex.test(str);

  return containsTags ? str?.replace(htmlTagsRegex, '') : str;
};

export const capitalize = (string: string) => {
  const lowercased = string.toLowerCase();

  return lowercased.charAt(0).toUpperCase() + lowercased.slice(1);
};
